




















































































































iframe#preview-frame{
  width: 100%;
  height: calc(100% - 5px);
}
:root{
  --vs-search-input-color: #000;
  --vs-selected-color: #fff;
  --vs-colors--dark: #000;
  --vs-search-input-bg: transparent;
  --vs-border-width:0;
  --vs-controls-color:#fff;
}
.v-select{
  &.rotator-delay span.vs__selected:after {
    content: 's';
  }
  &.min-rating span.vs__selected:after {
    content: 'Stars';
    padding-left: 2px;
  }
}
.vs__search[readonly]{
  display: none;
}
.v-select:not(.vs--open){
  .vs__search{
    display: none;
  }

}
span.vs__selected{
  right: 0;
}
.vs--unsearchable {
  &.vs--loading .vs__selected, &.vs--open .vs__selected {
    position: relative !important;
  }
}

div#image_overlay_panel{
  z-index: 100000000 !important;
}
.tooltip-inner{
  max-width: 400px;
}
.btn-check {
  position: absolute;
  clip: rect(0,0,0,0);
  pointer-events: none;
}
.widget-modal{
  .modal-body{
    padding: 20px;
  }
}
.widget-creator{
  margin: 0;
  padding-top: 0;
  padding-bottom: 10px;
  h3{
    font-size: 16px;
  }
  input, button, .btn{
    font-size: 14px;
  }
}
.pac-container{
  z-index: 100000;
}
.widget-editor{
  padding: 0 !important;
  .modal-dialog {
    width: 100%;
    height: 100%;
    max-width: 100%;
    margin: 0;
    padding: 0;
  }
  .modal-content {
    height: auto;
    min-height: 100%;
    border-radius: 0;
    border: 0;
    .modal-header{
      background: #4f688f;
      color: #fff;
      padding: 5px 15px;
      border-radius: 0;
      border: 0;
    }
    .modal-body{
      padding: 0;
    }
  }
  .left-nav-side{
    width: 50px;
    font-size: 10px;
    margin-right: 10px;
    text-align: center;
    button{
      margin-bottom: 15px;
      border: 0;
      appearance: none;
      background: none;
      color: #bfbfbf;
      i{
        font-size: 25px;
        line-height: 25px;
      }
      &.active, &:hover{
        color: #fff;
      }
    }
  }
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  .nav-sidebar{
    width: calc(100% - 60px);
    > li{
      list-style: none;
      width: 100%;
      > h4{
        margin-top: 0;
        color: #fff;
      }
      > ul{
        > li{
          background: #51688f;
          border-radius: 8px;
          padding: 5px 10px;
          margin-bottom: 10px;
          width: 100%;
          clear: both;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          > span{
            flex: 1;
            flex-wrap: nowrap;
          }
          .vue-js-switch{
            margin-bottom: 0;
          }
          /*.vue-js-switch{
            display: inline-flex;
            flex: 1;
            justify-content: flex-end;
            margin-bottom: 0;
          }
          .v-select{
            display: inline-flex;
            flex: 1;
            justify-content: flex-end;
            span.vs__selected{
              position: relative !important;
              + input{
                position: absolute;
                clip: rect(0, 0, 0, 0);
              }
            }
          }*/
        }
      }
    }
    .style-selector{
      label{
        min-height: 120px;
        &:hover{
          color: #fff;
        }
        width: 50%;
        border: 4px solid #51688f;
        &.checked{
          border: 4px solid #00b050;
        }
        img{
          max-width: 100%;
          height: auto;

        }
        span{

        }
      }
    }
    .radius-selector{
      label.btn{
        padding: 3px;
        margin-left: 7px;
        margin-bottom: 0;
        background: #5f7cab;
        &.checked, &:hover{
          background: #00b050;
        }
        img{
          max-height: 18px;
        }
      }
    }
    .v-select ul[role="listbox"]{
      z-index: 1000;
      color: #000;
    }
/*    .v-select:not(.vs--open){
      .vs__search{
        display: none;
      }
    }*/

  }
  .sidebar{
    background-color: #284373;
    color: #fff;
    padding-right: 20px;
    padding-top: 20px;
    min-height: calc(100vh - 52px);
    max-width: 360px;
    width: 100%;
  }
  .editor-content{
    flex: 1;
  }
  --track-width: 45px !important;
  --track-height: 25px !important;
  --track-active-color: #2196F3;
}
